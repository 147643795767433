.dashboard-error-page-container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem;
    gap: 2rem;
    width: 100%;
    height: 100vh;
    background-image: linear-gradient(45deg,var(--vision-dashboard-chartsContainerBorder-color), transparent)

}

.dashboard-error-page-container .btn-container{
   display: flex;
   flex-direction: row;
   gap: 1rem;
   justify-content: center;
 
}

.reload-btn-wrapper button,
.sign-out-btn-wrapper button{
    width: 120px;
 
}

.reload-btn-wrapper button
{
    color: white;
    background-color:var(--vision-dashboard-primary-strong-color) 
}

.sign-out-btn-wrapper button{
   color:white;
    background-color:var(--vision-dashboard-log-out2-btn-color)
}

.dashboard-error-page-container .error-text{
    text-align: center;
}