.dashboard-header{
  display: flex;
    justify-content: center;
    background: rgb(66 60 60 / 87%);
    color: #fff;
    padding: 5px;
}

.dashboard-header .layout-container{
  right: 0;
    position: absolute;
    padding: 0 10px;
    display: flex;
    top: 12px;
}

.width100percent{
  width: 100%;
}

#donutchart, #piechart, #barchart, #linechart{
  height: 70vh;
}

.select-container{
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding: 0.5rem 0;
  margin-right: 10px;
}

.MuiGrid-container{
  padding: 1rem 2rem;
}

#barchart, #linechart{
  display: flex;
  justify-content: center;
  align-items: center;
}

.css-2m9kme-MuiFormControl-root{
  width: auto !important;
  margin-right: 3rem !important;
}

#barchart, #piechart, #donutchart, #linechart {
  display: block;
}

#bar-chart-year-filter-container
, #pie-chart-year-filter-container,
#donut-chart-year-filter-container,
#line-chart-year-filter-container{
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: row;
  padding: 5px 0;
  
}

#bar-chart-year-filter-container > div, 
#pie-chart-year-filter-container > div,
#donut-chart-year-filter-container > div,
#line-chart-year-filter-container > div{
  margin: 0 10px;
}

.export-btn-container{
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 5px 0;
}

#barchart, 
#donutchart,
#piechart,
#linechart{
  position: relative;
}

#barchart .export-btn-container,
#donutchart .export-btn-container,
#piechart .export-btn-container,
#linechart .export-btn-container{
  position: absolute;
  top: 2rem;
  right: 0;
}


 .bar-chart-data-export-container,
 .donut-chart-data-export-container,
 .pie-chart-data-export-container,
 .line-chart-data-export-container
 {
   padding: 1rem;
 }


/* jquery ui tool tip*/
.ui-tooltip, .arrow:after {
  background: black;
  border: 2px solid white;
}
.ui-tooltip {
  padding: 10px 20px;
  color: white;
  border-radius: 20px;
  font: bold 14px "Helvetica Neue", Sans-Serif;
  text-transform: uppercase;
  box-shadow: 0 0 7px black;
}
.arrow {
  width: 70px;
  height: 16px;
  overflow: hidden;
  position: absolute;
  left: 50%;
  margin-left: -35px;
  bottom: -16px;
}
.arrow.top {
  top: -16px;
  bottom: auto;
}
.arrow.left {
  left: 20%;
}
.arrow:after {
  content: "";
  position: absolute;
  left: 20px;
  top: -20px;
  width: 25px;
  height: 25px;
  box-shadow: 6px 5px 9px -9px black;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.arrow.top:after {
  bottom: -20px;
  top: auto;
}

.tile-lay-out-container{
  /* padding-left: 10px; */
}