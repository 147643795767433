
:root{
  --vision-dashboard-primary-strong-color: #3f51b5;
  --vision-dashboard-colourChartText-color: #cbcbcb;
  --vision-dashboard-colourBackground-color: #333333;
  --vision-dashboard-colourCardLine-color: #cbcbcb;
  --vision-dashboard-colourCardTextTitle-color: #cbcbcb;
  --vision-dashboard-colourChartLine-color: #cbcbcb;
  --vision-dashboard-colourKey-color: #e20000;
  --vision-dashboard-colourStringChartSeries-color: #BAE08D,#8AB6EF,#F5CE85,#B0C1D4,#F78F8F;

  --vision-dashboard-log-out-btn-color: #F78F8F;

  --vision-dashboard-version-check-btn-color: #505886;

  --vision-dashboard-log-out2-btn-color: #e20000;

  --map-bubble-color: #BAE08D;
  --map-bubble-opacity: 0.7;

  --map-height: 290px;
  
  --vision-dashboard-barChartContainer-width: 95%;
  --vision-dashboard-barChartContainer-height: 85%;

  --vision-dashboard-doughnutChartContainer-width: 100%;
  --vision-dashboard-doughnutChartContainer-height: 85%;

  --vision-dashboard-funnelChartContainer-width: 98%;
  --vision-dashboard-funnelChartContainer-height: 90%;

  --vision-dashboard-gaugeChartContainer-width: 100%;
  --vision-dashboard-gaugeChartContainer-height: 90%;

  --vision-dashboard-lineChartContainer-width: 98%;
  --vision-dashboard-lineChartContainer-height: 90%;

  --vision-dashboard-pieChartContainer-width: 100%;
  --vision-dashboard-pieChartContainer-height: 85%;

  --vision-dashboard-stackedbarContainer-width: 95%;
  --vision-dashboard-stackedbarContainer-height: 85%;

  --vision-dashboard-gaugeChartContainer-top: 20;
  --vision-dashboard-gaugeChartContainer-bottom: 0;
  --vision-dashboard-gaugeChartContainer-left: 0;
  --vision-dashboard-gaugeChartContainer-right: 0;

  --ChartContainer-cols-lg: 60;
  --ChartContainer-cols-md: 10;
  --ChartContainer-cols-sm: 6;
  --ChartContainer-cols-xs: 4;
  --ChartContainer-cols-xxs: 2;
  --ChartContainer-rowHeight: 5;


  --vision-dashboard-chartsContainerBorder-color:  #c0c0c0;

  --Token-Expiration-Poll-In-Interval: 120;

  --Zoom-Factor: -1;

}

html {
  
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Roboto,"Helvetica Neue",sans-serif !important;
  height: 100%;
  overflow: hidden;
}
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Roboto,"Helvetica Neue",sans-serif !important;
  overflow: hidden;
  height: 100%;
}

#root {
  width: 100%
}

h1{

  font-family: Roboto,"Helvetica Neue",sans-serif;
  font-weight: 300;
  margin: 0 0 12px;
  font-size: 96px !important;
  line-height: 96px !important;
  letter-spacing: -1.5px;
  
  }
  
  h2{
  
      font-size: 60px !important;
      line-height: 60px !important;
      letter-spacing: -.5px;
      font-family: Roboto,"Helvetica Neue",sans-serif;
      font-weight: 300;
      margin: 0 0 12px;
  
  }
  
  h3{
  
      font-family: Roboto,"Helvetica Neue",sans-serif;
      font-weight: 400;
      margin: 0 0 12px;
      font-size: 48px !important;
      line-height: 50px !important;
      letter-spacing: 0;
  }
  
  
  h4{
  font-family: Roboto,"Helvetica Neue",sans-serif;
  font-weight: 400;
  margin: 0 0 12px;
  
  font-size: 34px !important;
  line-height: 40px !important;
  letter-spacing: .25px;
  }
  
  h5{
  font-family: Roboto,"Helvetica Neue",sans-serif;
  line-height: 32px !important;
  margin: 0 0 12px;
  font-size: 24px !important;
  font-weight: 400;
  letter-spacing: 0;
  
  }
  
  h6{
  font-size: 20px !important;
      font-weight: 500;
      letter-spacing: .15px;
      font-family: Roboto,"Helvetica Neue",sans-serif;
      line-height: 32px !important;
      margin: 0 0 12px;
  }
  
  p{
  font-size: 1rem !important;
  font-family: Roboto,"Helvetica Neue",sans-serif;
  line-height: 1.5 !important;
  font-weight: 400;
  letter-spacing: .03125em;
  margin: 0 0 12px;
  
  }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.dropdownuser
{
  margin-top: 50px;
  margin-left: 80px;
  margin-bottom: 25px;
}

.download-svg,
.reset-svg
{
  fill: var( --vision-dashboard-colourKey-color)
}